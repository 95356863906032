#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: var(--primary);
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px #141840, 0 0 10px #05092b;
    opacity: 1.0;
}
